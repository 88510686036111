<template>
  <div class="wrapper">
    <div class="flex items-center justify-center py-50" v-if="offerLetter.loading">
      <sm-loader />
    </div>
    <offer-letter
      v-else-if="offerLetter.data"
      :offer-letter="offerLetter.data"
      :default-interest-rate="defaultInterestRate"
      @accepted="accepted"
      @rejected="rejected"
    />
    <div class="px-10 md:px-32" v-else-if="offerLetter.error">
      <div class="alert alert-red-soft">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(offerLetter) }}</span>
      </div>
    </div>
    <div v-else>Null</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$options.basicForm([]),
        offerLetter: this.$options.resource(null),
        configurations: this.$options.resource({})
      }
    },
    computed: {
      loanId () {
        return this.$route.params.loanId;
      },
      defaultInterestRate() {
        return this.configurations.data?.personal_default_charge;
      }
    },
    beforeMount () {
      this.getLoanOfferLetter();
      this.getConfigurations();
    },
    methods: {
      accepted() {
        this.offerLetter.data.status = 'accepted';
        this.$router.push({ name: 'dashboard' });
      },
      rejected() {
        this.offerLetter.data.status = 'rejected';
        this.$router.push({ name: 'dashboard' });
      },
      async getConfigurations() {
        this.configurations.loading = true;
        await this.sendRequest('personal.configurations', {
          success: response => {
            this.configurations.data = response.data;
          },
          error: error => {
            this.configurations.error = error;
          }
        });
        this.configurations.loading = false;
      },
      async getLoanOfferLetter() {
        this.offerLetter.loading = true;
        await this.sendRequest('personal.loans.offerLetter.loanOfferLetter', this.loanId, {
          success: response => {
            const { data: { offer_letter: offerLetter } } = response.data;
            this.offerLetter.data = offerLetter;
          },
          error: error => {
            this.offerLetter.error = error;
          }
        });
        this.offerLetter.loading = false;
      }
    }
  }
</script>

<style lang="sass" scoped>
  .wrapper
    @apply py-20
</style>
